import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faComment } from '@fortawesome/free-regular-svg-icons';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import { Order as OrderType } from '../../../../global';

type OrderProps = {
  order: OrderType;
};

const Order = ({ order }: OrderProps) => {
  const navigate = useNavigate();
  return (
    <li
      className={
        'flex justify-between mx-2 mb-2 h-18 list-none shadow-md rounded-lg pb-4 px-2 pt-2 cursor-pointer  bg-white ' +
        (order.status === 'accepted' && order.fulfillmentStatus === 'failed'
          ? 'border-yellow-500 border-2'
          : (order.status === 'pending' && order.color === 'red') ||
            (order.status === 'accepted' && order.color === 'red')
          ? 'border-lh-red border-2'
          : order.status === 'canceled'
          ? 'border-lh-red border-2'
          : order.status === 'rejected'
          ? 'border-yellow-500 border-2'
          : order.status === 'fulfilled'
          ? 'border-green-500 border-2'
          : 'border-0')
      }
      onClick={() => {
        navigate(`/orders/${order.id}`);
      }}
    >
      <div className="grid grid-cols-10 w-full">
        <div className="col-span-6">
          <p className="text-lh-head-black align-middle col-span-4 font-medium">
            <FontAwesomeIcon
              icon={order.createdOn === 'chatbot' ? faComment : faUserCircle}
              className="text-lh-head-black"
            />{' '}
            {order.user.name}
          </p>
          <p className="text-sm text-lh-text-black">
            {dayjs(order.createdAt).format('DD.MM.YYYY HH:mm')}
          </p>
        </div>
        <p className="col-span-3 text-right self-center mt-1 text-lh-head-black font-semibold">
          {(order.status === 'accepted' && order.totalDelivered > 0) ||
          order.status === 'fulfilled'
            ? order.totalDelivered + ' / ' + order.totalOrdered
            : order.totalOrdered}{' '}
          <span className="text-gray-500 font-light">units</span>
        </p>
        <p className="col-span-1 text-right self-center mt-1 text-lh-head-black font-bold mr-4">
          <FontAwesomeIcon
            icon={faAngleRight}
            className="text-lh-head-black font-bold align-middle"
          />
        </p>
      </div>
    </li>
  );
};

export default Order;

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Alert from '../../components/ui/Alert';
import Back from '../../components/ui/Back';
import Button from '../../components/ui/Button';
import Card from '../../components/ui/Card';
import { useSignInWithGoogle } from '../../lib/auth/use-signInWithGoogle';
import { useAuth } from '../../lib/hooks/use-auth';
import { auth } from '../../lib/utils/firebase';

const GoogleSignIn = () => {
  const { user } = useAuth();
  const [signInWithGoogle, loading, error] = useSignInWithGoogle(auth);
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState<boolean>(false);

  useEffect(() => {
    if (error) {
      setShowAlert(true);
    } else if (showAlert) {
      setShowAlert(false);
    }
    return () => {
      setShowAlert(false);
    };
  }, [error]);

  useEffect(() => {
    if (user !== undefined && user !== null) navigate('/', { replace: true });
  }, [user, navigate]);

  return (
    <Card className="pt-24 px-4">
      <Alert
        message={error && error.message}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
        title="Error"
      />
      <div className="flex justify-between">
        <Back to="/enter" />
      </div>
      <div className="flex justify-center">
        <Button color={'gray'} onClick={signInWithGoogle}>
          Sign in with Google
        </Button>
      </div>
    </Card>
  );
};

export default GoogleSignIn;

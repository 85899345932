import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { debounce } from '../../lib/utils/debounce';
import Input from '../forms/Input';

type SearchAndSortProps = {
  categories: { [key: string]: string };
  onCategoryChange: Function;
  setSearchText?: Function;
  selectedCategory: string;
  displayCategory: boolean;
  displaySearch?: boolean;
  className?: string;
  searchPlaceHolder?: string;
  searchDisabled?: boolean;
};

const SearchAndSort = ({
  categories,
  onCategoryChange,
  selectedCategory,
  displayCategory,
  displaySearch,
  className,
  searchPlaceHolder,
  setSearchText,
  searchDisabled,
}: SearchAndSortProps) => {
  const { register } = useForm({
    mode: 'all',
  });

  const executeSearch = debounce((event) => {
    const text = event.target.value;
    if (text.length > 2) {
      setSearchText(text);
    } else {
      setSearchText(null);
    }
  }, 500);

  return (
    <>
      <div
        className={
          'w-full grid grid-cols-12 justify-center md:grid-rows-2 ' + className
        }
      >
        {displaySearch && (
          <>
            <div
              className={
                'text-center col-span-8 md:col-span-4 md:col-start-5 col-start-3'
              }
            >
              <Input
                type="text"
                name="searchText"
                placeholder={searchPlaceHolder ? searchPlaceHolder : ''}
                register={register}
                options={{ onChange: executeSearch }}
                className={'w-full'}
                disabled={searchDisabled ? searchDisabled : false}
              />
            </div>
            <div className={'text-left col-span-1'}>
              <button
                aria-label="Search"
                aria-expanded={null}
                onClick={() => {}}
                type="button"
                className="justify-around w-8 h-8 bg-transparent cursor-pointer p-0 focus:outline-none"
              >
                <FontAwesomeIcon icon={faSearch} className="text-lg" />
              </button>
            </div>
          </>
        )}
        {displayCategory && (
          <div
            className={
              'text-right md:col-span-2 col-span-8 md:col-start-10 col-start-3 md:row-start-1 row-start-2 w-full'
            }
          >
            <select
              className={
                'text-center cursor-pointer border-2 w-full bg-gray-200 rounded-lg'
              }
              onChange={(event) => onCategoryChange(event)}
              value={selectedCategory}
            >
              {categories &&
                Object.entries(categories).map((entry) => {
                  const [key, value] = entry;
                  return (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  );
                })}
            </select>
          </div>
        )}
      </div>
    </>
  );
};
export default SearchAndSort;

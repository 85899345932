import { faBell } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const NotificationBadge = ({ count }) => {
  return (
    <Link to={'/notifications'} className="fixed top-5 right-5 z-30">
      <FontAwesomeIcon icon={faBell} className=" text-2xl" />
      {count > 0 && (
        <span className="absolute -top-1 -right-2 px-1 rounded-full bg-red-600 text-white text-xs">
          {count}
        </span>
      )}
    </Link>
  );
};

export default NotificationBadge;

import { Product as ProductType } from '../../../../global';

type ProductProps = {
  product: ProductType;
};

const ProductItem: React.FC<any> = ({ product }: ProductProps) => {
  return (
    <>
      <h3 className="col-span-5 py-1 text-lh-text-black">{product.name}</h3>
      <p className="col-span-3 col-start-7 mb-4 text-right py-1 px-3 font-semibold text-lh-text-black">
        {product.quantity}
      </p>
      <p className="col-span-1 py-1 text-gray-500 font-thin">units</p>
    </>
  );
};

export default ProductItem;

import React, { useEffect, useState } from 'react';
import Content from '../../../components/layout/Content';
import { useNavigate, useParams } from 'react-router-dom';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from 'firebase/firestore';
import { firestore } from '../../../lib/utils/firebase';
import { Product } from '../../../../../global';
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';

type Props = {};
type ProductParams = 'id';

export default function EditProduct({}: Props) {
  const navigate = useNavigate();
  const { id } = useParams<ProductParams>();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState(0);
  const [file, setFile] = useState(null as any);
  const [categories, setCategories] = useState([] as any[]);
  const [category, setCategory] = useState('');
  const [error, setError] = useState('');
  const [newProductImage, setNewProductImage] = useState('' as any);
  const [imageChanged, setImageChanged] = useState(false);
  const [imgName, setImgName] = useState('');

  useEffect(() => {
    // load categories from firestore async
    async function loadCategories() {
      // get categories from firestore
      const categoriesQuery = collection(firestore, 'categories');
      await getDocs(categoriesQuery)
        .then((snapshot) => {
          let categories = [];
          snapshot.docs.map((doc) => {
            const category = {
              id: doc.id,
              name: doc.data().name,
            };
            categories.push(category);
          });
          //sort categories by name
          categories.sort((a, b) => (a.name > b.name ? 1 : -1));
          setCategories(categories);
        })
        .catch((error) => {
          console.log('ERROR:', error);
        });
    }
    loadCategories();

    if (!id) return;

    // load product from firestore async
    async function loadProduct() {
      const productRef = doc(firestore, `products/${id}`);
      await getDoc(productRef).then((snapshot) => {
        setName(snapshot.data().name);
        setDescription(snapshot.data().description);
        setPrice(snapshot.data().price);
        setFile(snapshot.data().img);
        setImgName(snapshot.data().imgName);
        setCategory(snapshot.data().category);
      });
    }
    loadProduct();
  }, []);

  function handleFileInputChange(e: any) {
    setImageChanged(true);
    const file = e.target.files[0];
    setFile(file);
    console.log('file', file);
    setNewProductImage(URL.createObjectURL(file));
  }

  async function handleSubmit() {
    if (category === '' || category === 'All') {
      setError('Please select a category');
    } else if (!name) {
      setError('Please enter a name');
    } else if (!description) {
      setError('Please enter a description');
    } else if (!price || price === 0) {
      setError('Please enter a price');
    } else if (!file) {
      setError('Please select an image');
    } else {
      if (imageChanged && imgName !== '') {
        console.log('image has changed');
        const storage = getStorage();

        // Create a reference to the file to delete
        const oldRef = ref(storage, `products/${imgName}`);

        // Delete the file
        await deleteObject(oldRef)
          .then(() => {
            // File deleted successfully
            console.log('Old image deleted successfully');

            console.log('uploading image to storage');

            const storageRef = ref(storage, `products/${imgName}`);
            const uploadTask = uploadBytesResumable(storageRef, file);
            uploadTask.on('state_changed', (snapshot) => {
              // progress function ...
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
            });
            uploadTask.then((snapshot) => {
              getDownloadURL(snapshot.ref)
                .then((url) => {
                  const product: Product = {
                    name: name,
                    description: description,
                    price: price,
                    img: url,
                    imgName: imgName,
                    category: category,
                  };
                  updateProduct(product);
                })
                .then(() => {
                  navigate('../products');
                });
            });
          })
          .catch((error) => {
            // Uh-oh, an error occurred!
            setError('Uh-oh, an error occurred deleting the old file!');
            console.log('Uh-oh, an error occurred deleting the old file!');
          });
      } else {
        console.log('image has not changed');
        const product: Product = {
          name: name,
          description: description,
          price: price,
          img: file,
          imgName: imgName,
          category: category,
        };
        await updateProduct(product);

        navigate('../products');
      }
    }
  }

  async function updateProduct(product: Product) {
    const productRef = doc(firestore, `products/${id}`);

    console.log('product:', product);

    updateDoc(productRef, product)
      .then(() => {
        console.log('Product successfully updated!');
        navigate('../products');
      })
      .catch((error) => {
        console.error('Error updating product: ', error);
      });
  }

  return (
    <>
      <Content>
        <div className="text-2xl font-bold text-left">Edit Product</div>
        <div className="w-11/12 md:w-1/3 mt-4">
          <div>
            <label htmlFor="name">Name</label>
            <input
              value={name}
              type="text"
              name="name"
              id="name"
              className="w-full border"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div>
            <label htmlFor="description">Description</label>
            <textarea
              value={description}
              name="description"
              id="description"
              className="w-full border h-24"
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </div>
          <div>
            <label htmlFor="category">Category</label>
            <select
              value={category}
              name="category"
              id="category"
              className="w-full border"
              onChange={(e) => {
                console.log('e.target.value:', e.target.value);
                setCategory(e.target.value);
              }}
            >
              {categories.map((category) => {
                return (
                  <option key={category.id} value={category.name}>
                    {category.name}
                  </option>
                );
              })}
            </select>
          </div>

          <div>
            <label htmlFor="price">Price</label>
            <input
              value={price}
              type="number"
              name="price"
              id="price"
              className="w-full border"
              onChange={(e) => {
                setPrice(parseInt(e.target.value));
              }}
            />
          </div>
          <div>
            <label htmlFor="image">Image</label>
            <input
              type="file"
              accept="image/*"
              name="image"
              id="image"
              className="w-full border"
              onChange={handleFileInputChange}
            />
          </div>
          {file && !imageChanged && (
            <>
              <img src={file} alt="" className="w-48 shadow" />
            </>
          )}
          {newProductImage && (
            <>
              <img src={newProductImage} alt="" className="w-48 shadow" />
            </>
          )}

          <div className="text-red-500 mt-4">
            <p>{error}</p>
          </div>

          <div className="flex justify-between mt-4">
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => {
                navigate('../products');
              }}
            >
              Cancel
            </button>

            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              Update
            </button>
          </div>
        </div>
      </Content>
    </>
  );
}

import {
  collection,
  doc,
  FirestoreError,
  onSnapshot,
  Query,
  query,
  Unsubscribe,
  where,
} from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { docToJSON, firestore } from '../utils/firebase';
import { useAuth } from './use-auth';
import { usePagination } from '../utils/usePagination';
import { FirebaseError } from 'firebase/app';
import { Associate, User } from '../../../../global';
import {
  SearchQuery,
  usePaginationTypesense,
} from '../utils/usePaginationTypesense';

export type AssociatesHook = {
  error: FirebaseError;
  hasMore: boolean;
  load: () => void;
  loading: boolean;
  reset: () => void;
  associates: Associate[];
};

export type AssociateCategory =
  | 'all'
  | 'numberWrong'
  | 'noWhatsApp'
  | 'enabledWhatsApp'
  | 'phoneChanged'
  | 'actionRequired';

export type AssociateOptions = {
  roles: string | string[];
  category?: AssociateCategory;
  searchText?: string;
  pageSize?: number;
};

export const useAssociates = ({
  roles,
  category,
  pageSize = 10,
}: AssociateOptions): AssociatesHook => {
  const { id } = useAuth();
  const [associatesQuery, setAssociatesQuery] = useState<Query>();
  const { error, hasMore, load, loading, reset, values } = usePagination(
    associatesQuery,
    pageSize
  );

  useEffect(() => {
    if (id) {
      let newQuery = query(
        collection(firestore, `users/${id}/associates`),
        where('active', '==', true)
      );
      if (roles && typeof roles === 'string') {
        newQuery = query(newQuery, where('roles', 'array-contains', roles));
      }
      if (roles && Array.isArray(roles)) {
        newQuery = query(newQuery, where('roles', 'array-contains-any', roles));
      }
      if (category) {
        switch (category) {
          case 'actionRequired': {
            newQuery = query(newQuery, where('actionRequired', '==', true));
            break;
          }
          case 'numberWrong': {
            newQuery = query(newQuery, where('phoneCorrect', '==', false));
            break;
          }
          case 'noWhatsApp': {
            newQuery = query(newQuery, where('hasWhatsApp', '==', false));
            break;
          }
          case 'enabledWhatsApp': {
            newQuery = query(newQuery, where('hasWhatsApp', '==', true));
            break;
          }
          case 'phoneChanged': {
            newQuery = query(newQuery, where('phoneChanged', '==', true));
            break;
          }
        }
      }
      setAssociatesQuery(newQuery);
    } else if (associatesQuery) {
      setAssociatesQuery(undefined);
    }
  }, [id, roles, pageSize, category]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      associates: values
        ? values.map((v) => docToJSON(v) as Associate)
        : values,
    }),
    [error, hasMore, load, loading, reset, values]
  );
};

export const useAssociatesTypesense = ({
  roles,
  category,
  searchText,
  pageSize = 10,
}) => {
  const { id, typesenseKey } = useAuth();
  const [associatesQuery, setAssociatesQuery] = useState<SearchQuery>();
  const { error, hasMore, load, loading, reset, values } =
    usePaginationTypesense(associatesQuery, pageSize, 'user_associate', typesenseKey);

  useEffect(() => {
    let categoryFilter = 'active:=true';
    if (category) {
      switch (category) {
        case 'actionRequired': {
          categoryFilter += '&&actionRequired:=true';
          break;
        }
        case 'numberWrong': {
          categoryFilter += '&&phoneCorrect:=false';
          break;
        }
        case 'noWhatsApp': {
          categoryFilter += '&&hasWhatsApp:=false';
          break;
        }
        case 'enabledWhatsApp': {
          categoryFilter += '&&hasWhatsApp:=true';
          break;
        }
        case 'phoneChanged': {
          categoryFilter += '&&phoneChanged:=true';
          break;
        }
      }
    }

    if (typesenseKey && id && searchText) {
      let search = {
        q: searchText,
        query_by: 'name',
        filter_by: `${categoryFilter}`,
      };

      setAssociatesQuery(search);
    } else if (associatesQuery) {
      setAssociatesQuery(undefined);
    }
  }, [id, roles, pageSize, category, searchText, typesenseKey]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      associates: values
        ? values.map((v) => {
          return { ...v.document, id: v.document.userId } as Associate;
        })
        : values,
    }),
    [error, hasMore, load, loading, reset, values]
  );
};

export const useAssociate = (id?: string) => {
  const { id: userId } = useAuth();
  const [loading, setLoading] = useState(false);
  const [associate, setAssociate] = useState<User | undefined>();
  const [error, setError] = useState<FirestoreError>();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (userId && id) {
      setLoading(true);
      const ref = doc(firestore, `users/${userId}/associates`, id);
      unsubscribe = onSnapshot(
        ref,
        (doc) => {
          if (doc.exists) {
            setAssociate(docToJSON(doc) as User);
          } else {
            setAssociate(null);
          }
          setLoading(false);
        },
        (error: FirestoreError) => {
          setLoading(false);
          setError(error);
          setAssociate(undefined);
        }
      );
    }
    return unsubscribe;
  }, [id, userId]);

  return {
    associate,
    error,
    loading,
  };
};

import { RegisterOptions } from 'react-hook-form';
import { Product as ProductType } from '../../../../global';
import Input from '../forms/Input';

type ProductProps = {
  product: ProductType;
  register: any;
  index: number;
};

const ProductsDelivered: React.FC<ProductProps> = ({
  product,
  register,
  index,
}) => {
  const productOptions: RegisterOptions = {
    valueAsNumber: true,
    min: {
      value: 0,
      message: 'Product cannot be negative!',
    },
    max: {
      value: 10000,
      message: "You can only order 10'000 bags at a time!",
    },
  };

  return (
    <>
      <label htmlFor={product.name} className="col-span-4 pt-1">
        {product.name}
      </label>
      <Input
        register={register}
        placeholder="0"
        name={product.name as never}
        type="number"
        options={productOptions}
        className="col-span-4 col-start-8 text-right"
      />
      <label htmlFor="classic" className="col-spa-1 ml-2 pt-1">
        units
      </label>
    </>
  );
};

export default ProductsDelivered;

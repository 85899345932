import { faSync } from '@fortawesome/free-solid-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

type ButtonBottomRightProps = {
  onClick: () => void;
  blocked: boolean;
  icon?: FontAwesomeIconProps['icon'];
  color?: string;
  arialabel?: string;
};

const ButtonBottomRight = ({
  onClick,
  blocked,
  color,
  arialabel,
  icon = faSync,
}: ButtonBottomRightProps) => {
  const style = clsx(
    'fixed bottom-5 right-6 flex justify-center items-center w-16 h-16 cursor-pointer p-0 focus:outline-none z-30 bg-white shadow-md drop-shadow-md rounded-full',
    color === 'red' && 'border-2 border-red-600 text-red-600'
  );

  return (
    <button
      aria-label={arialabel}
      aria-expanded={null}
      onClick={onClick}
      type="button"
      className={style}
      disabled={blocked}
    >
      <FontAwesomeIcon icon={icon} className="text-lg" />
    </button>
  );
};

export default ButtonBottomRight;

import React from 'react';

type ContentProps = {
  topBar?: boolean;
  children?: JSX.Element | JSX.Element[];
};

const Content = ({ topBar, children }: ContentProps) => {
  const css = topBar ? 'top-40' : 'top-20';
  return (
    <div
      className={`flex justify-center flex-col items-center absolute w-full ${css}`}
    >
      {children}
    </div>
  );
};

export default Content;

import React, { useEffect, useState } from 'react';
import Content from '../../components/layout/Content';
import { firestore } from '../../lib/utils/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { Product } from '../../../../global';
import Button from '../../components/ui/Button';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

type Props = {};

export default function Products({}: Props) {
  const navigate = useNavigate();
  const [products, SetProducts] = useState([] as Product[]);
  const [category, setCategory] = useState('');

  // load products from firestore async
  useEffect(() => {
    async function loadProducts() {
      // get products from firestore
      const productsQuery = collection(firestore, 'products');
      await getDocs(productsQuery)
        .then((snapshot) => {
          let products = [];
          snapshot.docs.map((doc) => {
            const product: Product = {
              id: doc.id,
              name: doc.data().name,
              description: doc.data().description,
              price: doc.data().price,
              img: doc.data().img,
              category: doc.data().category,
            };

            // add product to products array
            products.push(product);
          });
          SetProducts(products);
        })
        .catch((error) => {
          console.log('ERROR:', error);
        });
    }
    loadProducts();
  }, []);

  // filter products by category
  useEffect(() => {
    async function loadProducts() {
      // get products from firestore
      const productsQuery = collection(firestore, 'products');

      await getDocs(productsQuery)
        .then((snapshot) => {
          let products = [];
          snapshot.docs.map((doc) => {
            const product: Product = {
              id: doc.id,
              name: doc.data().name,
              description: doc.data().description,
              price: doc.data().price,
              img: doc.data().img,
              category: doc.data().category,
            };

            // add product to products array
            products.push(product);
          });
          SetProducts(products);
        })
        .catch((error) => {
          console.log('ERROR:', error);
        });
    }
    loadProducts();
  }, [category]);

  return (
    <>
      <Content>
        <div className="md:w-2/3">
          <Button
            text="New Product"
            color="blue"
            onClick={() => {
              navigate('new');
            }}
            className="my-4"
          ></Button>
          {products.map((product: Product) => {
            return (
              <div key={product.id} className="w-full mb-4 border-2 p-4 flex">
                <div className="p-5">
                  <Link to={`/products/${product.id}`}>
                    <img src={product.img} width="100" />
                  </Link>
                </div>
                <div className="p-5">
                  <div className="font-bold">{product.name}</div>
                  <p>{product.description}</p>
                  <p>Price: ₦{product.price}</p>
                  <p>Category: {product.category ? product.category : '-'}</p>
                </div>
                <div>
                  <Button
                    text="Edit"
                    color="blue"
                    onClick={() => {
                      navigate(`/products/edit/${product.id}`);
                    }}
                    className="my-4"
                  ></Button>
                </div>
              </div>
            );
          })}
        </div>
      </Content>
    </>
  );
}

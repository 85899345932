import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
import { useEffect } from 'react';
import { useMap } from '../../../lib/hooks/use-map';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Location } from '../../../../../global';
import { transform } from 'ol/proj';

interface IconUpdateLayerProps {
  oldLocation: Location;
  newLocation: Location;
  icon: string;
  name: string;
  showLabels?: boolean;
}

const IconUpdateLayer = ({
  oldLocation,
  newLocation,
  icon,
  name,
  showLabels = false,
}: IconUpdateLayerProps) => {
  const { map } = useMap();

  useEffect(() => {
    if (!map) return;

    const oldCoords = transform(
      [oldLocation.lng, oldLocation.lat],
      'EPSG:4326',
      'EPSG:3857'
    );

    const oldFeature = new Feature({
      geometry: new Point(oldCoords),
      name,
    });

    const oldColor = '#dc2626';

    oldFeature.setStyle(
      new Style({
        image: new Icon({
          color: oldColor,
          crossOrigin: 'anonymous',
          // For Internet Explorer 11
          imgSize: [20, 20],
          src: icon,
        }),
        text: new Text({
          text: 'Old ' + name,
          offsetY: 30,
          scale: 1.7,
          stroke: new Stroke({ color: '#ffffff', width: 2 }),
          fill: new Fill({ color: oldColor }),
        }),
      })
    );

    const newCoords = transform(
      [newLocation.lng, newLocation.lat],
      'EPSG:4326',
      'EPSG:3857'
    );

    const newFeature = new Feature({
      geometry: new Point(newCoords),
    });

    const newColor = '#16a34a';

    newFeature.setStyle(
      new Style({
        image: new Icon({
          color: newColor,
          crossOrigin: 'anonymous',
          // For Internet Explorer 11
          imgSize: [20, 20],
          src: icon,
        }),
        text: new Text({
          text: 'New ' + name,
          offsetY: 30,
          scale: 1.7,
          stroke: new Stroke({ color: '#ffffff', width: 2 }),
          fill: new Fill({ color: newColor }),
        }),
      })
    );

    const layer = new VectorLayer({
      source: new VectorSource({
        features: [oldFeature, newFeature],
      }),
    });
    map.addLayer(layer);
    map.getView().setCenter(newCoords);
    const bounds = layer.getSource().getExtent();
    map.getView().fit(bounds);
    let zoom = 20 - map.getView().getZoom();
    map
      .getView()
      .fit([
        bounds[0],
        bounds[1] - Math.pow(zoom, 4) - 50,
        bounds[2],
        bounds[3] + Math.pow(zoom, 4) + 50,
      ]);

    return () => {
      if (map) {
        map.removeLayer(layer);
      }
    };
  }, [oldLocation, newLocation, icon, name]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default IconUpdateLayer;

import { useEffect } from 'react';

export const useOnClickOutside = (ref: any, handler: any, block?: boolean) => {
  useEffect(() => {
    const listener = (event: any) => {
      if (block || !ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler, block]);
};

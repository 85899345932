import React, { useCallback } from 'react';
import { useEffect, useRef } from 'react';

type NewListProps = {
  children: React.ReactNode;
  hasMore?: boolean;
  pageSize?: number;
  load?: () => void;
  loading?: boolean;
  className?: string;
};

const List = ({
  loading,
  hasMore,
  pageSize,
  load,
  children,
  className,
}: NewListProps) => {
  const loader = useRef(null);

  const handleObserver = useCallback(
    (entires) => {
      const target = entires[0];
      if (target.isIntersecting && hasMore && !loading) {
        load();
      }
    },
    [hasMore, loading, load]
  );

  useEffect(() => {
    if (!load) return;
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
    return () => {
      if (loader.current) observer.unobserve(loader.current);
    };
  }, [handleObserver, load]);

  return (
    <ul
      className={
        'grid grid-col-12 w-full md:min-w-min md:w-1/2 auto-rows-min mx-2 my-4 px-6 py-4 overflow-auto ' +
        className
      }
    >
      {children}
      {hasMore && (
        <li
          ref={loader}
          key="loadmore"
          className="flex justify-center items-center w-full mx-2 h-18 list-none py-2"
        ></li>
      )}
    </ul>
  );
};
export default List;

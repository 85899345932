type CardProps = {
  children?: JSX.Element | JSX.Element[] | string;
  className?: string;
  [x: string]: any;
};

const Card = ({ children, className, ...rest }: CardProps) => {
  return (
    <section
      className={`w-full md:min-w-min md:w-1/2 mx-2 my-6 px-6 py-6 rounded-3xl shadow-lg border ${className}`}
      {...rest}
    >
      {children}
    </section>
  );
};

export default Card;

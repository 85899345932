import { useEffect, useState } from 'react';
import React from 'react';
import useStore from '../../lib/hooks/use-store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { useCheckAccess } from '../../lib/auth/use-checkAccess';
import { Bar, Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartjsPluginStacked100 from 'chartjs-plugin-stacked100';
import Card from '../../components/ui/Card';
import Content from '../../components/layout/Content';
import { useNavigate } from 'react-router-dom';
import Alert from '../../components/ui/Alert';
import { useUser } from '../../lib/hooks/use-user';
import { Confirm } from '../../components/forms/Confirm';
import { ConfirmTOS } from '../../components/forms/ConfirmTOS';
import { useSignOut } from '../../lib/auth/use-signout';

const Home = React.memo(() => {
  const [state, dispatch] = useStore({ shouldListen: false });
  const navigate = useNavigate();
  const signOut = useSignOut();
  const checkAccess = useCheckAccess();
  const { user, loading, error, update } = useUser();
  const date = new Date();
  const hours = date.getHours();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [block, setBlock] = useState(false);

  useEffect(() => {
    if (error) {
      setShowAlert(true);
    } else if (showAlert) {
      setShowAlert(false);
    }
    return () => {
      setShowAlert(false);
    };
  }, [error]);

  const goToOrders = (status: string) => {
    if (!checkAccess(['tae'])) {
      dispatch('SET_STATUS', [status]);
      navigate(`/orders`);
    } else if (checkAccess(['tae'])) {
      dispatch('SET_STATUS_LEAD', [status]);
      navigate(`/leads`);
    }
  };

  useEffect(() => {
    if (user && !user.appTOSAccepted) {
      setShowConfirmation(true);
    }
  }, [user]);

  const handleOnConfirm = () => {
    setBlock(false);
    setShowConfirmation(false);
    update({ appTOSAccepted: true });
  };

  const handleOnCancel = async () => {
    setBlock(false);
    setShowConfirmation(false);
    await signOut();
    navigate('/', { replace: true });
  };

  return (
    <Content>
      <ConfirmTOS
        title={
          'By using this application you consent to the collection and processing of your personal data in accordance with the Metro Mart Limited privacy policy. '
        }
        link="https://storage.googleapis.com/metro-mart-limited.appspot.com/Metro-Mart%20Data%20Privacy%20Policy.pdf"
        open={showConfirmation}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        setOpen={(open) => {
          setShowConfirmation(open);
        }}
        confirmText="Accept"
        cancelText="Logout"
      />
      <Alert
        message={error && error.message}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
        title="Error"
      />
      <Card>
        <div className="w-full grid grid-cols-12 gap-2 justify-center">
          <div
            className="grid grid-cols-12 col-span-12 h-12 text-md font-bold text-white text-center align-middle rounded-lg p-2 pt-3 bg-lh-dark-blue cursor-pointer"
            onClick={() => navigate('/profile')}
          >
            <h2 className="col-span-12 ">
              {user &&
                'Good ' +
                  (hours < 12 && hours > 3
                    ? 'morning'
                    : hours >= 12 && hours < 18
                    ? 'afternoon'
                    : 'evening') +
                  ', ' +
                  user.name}
            </h2>
          </div>
          <div
            className="grid grid-cols-12 col-span-6 h-24 text-lg font-bold text-white text-center align-middle rounded-lg p-2 pt-2 bg-lh-dark-blue cursor-pointer"
            onClick={() => goToOrders('pending')}
          >
            <p className="text-center col-span-12 font-medium -mb-2">Pending</p>
            <p className="text-center col-span-12 text-2xl">
              {user &&
              checkAccess(['fsa', 'distributor', 'sop', 'fsm']) &&
              user.ordersPending
                ? user.ordersPending
                : user && checkAccess(['tae']) && user.leadsAmountPending
                ? user.leadsAmountPending
                : 0}
            </p>
          </div>
          {!checkAccess(['tae']) && (
            <div
              className="grid grid-cols-12 col-span-6 h-24 text-lg font-bold text-white text-center align-middle rounded-lg p-2 pt-2 bg-lh-light-green"
              onClick={() => goToOrders('accepted')}
            >
              <p className="text-center col-span-12 font-medium -mb-2">
                Accepted
              </p>
              <p className="text-center col-span-12 text-2xl">
                {user && user.ordersAccepted}
              </p>
            </div>
          )}
          <div
            className="grid grid-cols-12 col-span-6 h-24 text-lg font-bold text-white text-center align-middle rounded-lg p-2 pt-2 bg-lh-light-blue cursor-pointer"
            onClick={() => goToOrders('fulfilled')}
          >
            <p className="text-center col-span-12 font-medium -mb-2">
              Fulfilled
            </p>
            <p className="text-center col-span-12 text-2xl">
              {user &&
              checkAccess(['fsa', 'distributor', 'sop', 'fsm']) &&
              user.ordersFulfilled
                ? user.ordersFulfilled
                : user && checkAccess(['tae']) && user.leadsAmountFulfilled
                ? user.leadsAmountFulfilled
                : 0}
            </p>
          </div>
          {!checkAccess(['tae']) && (
            <div
              className="grid grid-cols-12 col-span-6 h-24 text-lg font-bold text-white text-center align-middle rounded-lg p-2 pt-2 bg-lh-red"
              onClick={() => goToOrders('rejected')}
            >
              <p className="text-center col-span-12 font-medium -mb-2">
                Rejected
              </p>
              <p className="text-center col-span-12 text-2xl">
                {user && user.ordersRejected}
              </p>
            </div>
          )}
          {checkAccess(['sop', 'fsa', 'fsm']) && (
            <div
              className="grid grid-cols-12 col-span-6 h-36 text-lg font-bold text-white text-center align-middle rounded-lg p-2 pt-2 bg-lh-dark-blue cursor-pointer"
              onClick={() => navigate('/retailers/new')}
            >
              <p className="text-center col-span-12 font-medium -mb-2">
                Add new retailer
              </p>
              <p className="text-center col-span-12 text-2xl">
                <FontAwesomeIcon icon={faUserPlus} />
              </p>
            </div>
          )}
          {checkAccess(['fsa', 'distributor', 'sop', 'fsm']) && (
            <div className="grid grid-cols-12 col-span-6 col h-36 text-lg font-medium text-white text-center align-middle rounded-lg p-2 pt-3 bg-lh-dark-blue">
              <p className="text-center col-span-12 text-md pt-10">
                Coming soon!
              </p>
            </div>
          )}
          {/*
          <div
            className="grid grid-cols-12 col-span-6 row-span-2 text-lg font-bold text-white text-center align-middle rounded-lg p-2 pt-2 bg-lh-dark-blue cursor-pointer"
            onClick={() => goToRetailers('numberWrong')}
          >
            <p className="text-center col-span-12 font-medium -mb-2">
              No WhatsApp
            </p>
            <p className="text-center col-span-12 text-2xl">
              {/* TODO insert KPI
            </p>
          </div>
          <div
            className="grid grid-cols-12 col-span-6 row-span-2 text-lg font-bold text-white text-center align-middle rounded-lg p-2 pt-2 bg-lh-dark-blue cursor-pointer"
            onClick={() => goToRetailers('phoneChanged')}
          >
            <p className="text-center col-span-12 font-medium -mb-2">
              Phone changed
            </p>
            <p className="text-center col-span-12 text-2xl">
              {/* TODO insert KPI
            </p>
          </div>
          <div
            className="grid grid-cols-12 col-span-6 row-span-2 text-lg font-bold text-white text-center align-middle rounded-lg p-2 pt-2 bg-lh-dark-blue cursor-pointer"
            onClick={() => goToRetailers('all')}
          >
            <p className="text-center col-span-12 font-medium -mb-2">
              Total retailers
            </p>
            <p className="text-center col-span-12 text-2xl">
              {/* TODO insert KPI 
            </p>
          </div>
        */}
        </div>
      </Card>
    </Content>
  );
});

export default Home;

import { doc, updateDoc } from 'firebase/firestore';
import { UpdateNotificationData } from '../../../../global';
import { firestore } from '../utils/firebase';

export const updateNotification = async (
  userId: string,
  id: string,
  data: UpdateNotificationData
): Promise<void> => {
  const ref = doc(firestore, `users/${userId}/notifications`, id);
  await updateDoc(ref, data);
};

import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  ErrorOption,
  Path,
  RegisterOptions,
  UseFormReturn,
} from 'react-hook-form';

type Option = {
  key: string | number;
  label: React.ReactNode;
  value: string | number | string[] | null;
};

export interface SelectProps<T>
  extends Partial<Pick<UseFormReturn<T>, 'register' | 'formState'>> {
  name: Path<T>;
  items: Option[];
  options?: RegisterOptions;
  className?: string;
  placeholder?: string;
  error?: ErrorOption;
}

const Select = <T,>({
  register,
  name,
  options,
  items,
  className,
  placeholder,
  error,
  ...rest
}: SelectProps<T>) => {
  const [errorCSS, setErrorCSS] = useState<string>('');

  useEffect(() => {
    if (error) {
      setErrorCSS('border-red-500 bg-red-200 focus:ring-red-500');
    } else {
      setErrorCSS('');
    }
  }, [error]);
  return (
    <select
      {...register(name, options)}
      {...rest}
      defaultValue={null}
      className={`bg-gray-200 h-9 border-2 rounded-lg mb-1 mt-1 py-1 px-3 text-lh-text-black text-md ${errorCSS} ${className}`}
    >
      {placeholder && (
        <option key={'placeholder'} value={'placeholder'}>
          {placeholder}
        </option>
      )}
      {items.map(({ key, label, value }) => (
        <option key={key} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
};

export default Select;

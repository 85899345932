import dayjs from 'dayjs';
import { useState } from 'react';
import { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import Alert from '../../../components/ui/Alert';
import Card from '../../../components/ui/Card';
import { useOrder } from '../../../lib/hooks/use-orders';
import AcceptOrder from '../../../components/orders/AcceptOrder';
import RejectOrder from '../../../components/orders/RejectOrder';
import FulfillOrder from '../../../components/orders/FulfillOrder';
import Loader from '../../../components/ui/Loader';
import Back from '../../../components/ui/Back';
import Edit from '../../../components/ui/Edit';
import Button from '../../../components/ui/Button';
import { useCheckAccess } from '../../../lib/auth/use-checkAccess';
import Content from '../../../components/layout/Content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faComment,
  faUserCircle,
  faClock,
  faCheckCircle,
  faTimesCircle,
  faTrashAlt,
} from '@fortawesome/free-regular-svg-icons';
import { faTruck } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../../lib/hooks/use-auth';
import CancelOrder from '../../../components/orders/CancelOrder';
import Product from '../../../components/orders/Product';

type OrderDetailsParams = 'id';

const Order = () => {
  const { id } = useParams<OrderDetailsParams>();
  const { id: userId } = useAuth();
  const checkAccess = useCheckAccess();
  const navigate = useNavigate();
  const { accept, cancel, error, fulfill, loading, order, reject } =
    useOrder(id);
  const [showAccept, setShowAccept] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [showFulfill, setShowFulfill] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [displayEditButton, setDisplayEditButton] = useState(false);
  const [displayComment, setDisplayComment] = useState(false);
  const [displayAcceptButton, setDisplayAcceptButton] = useState(false);
  const [displayRejectButton, setDisplayRejectButton] = useState(false);
  const [displayFulfillButton, setDisplayFulfillButton] = useState(false);
  const [displayCancelButton, setDisplayCancelButton] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (error) {
      setShowAlert(true);
    } else if (showAlert) {
      setShowAlert(false);
    }
    return () => {
      setShowAlert(false);
    };
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (order) {
      switch (order.status) {
        case 'pending':
          setDisplayEditButton(true);
          setDisplayAcceptButton(true);
          setDisplayRejectButton(true);
          setDisplayCancelButton(order.createdBy.id === userId);
          break;
        case 'accepted':
          setDisplayFulfillButton(true);
          setDisplayComment(true);

          break;
        case 'fulfilled':
          setDisplayComment(true);

          break;
        case 'rejected':
          setDisplayComment(true);

          break;
        default:
          break;
      }
    }
  }, [order, userId]);

  const handleAccept = async (data) => {
    await accept(data.comment);
    navigate('/orders', { replace: true });
  };
  const handleReject = async (data) => {
    await reject(data.comment);
    navigate('/orders', { replace: true });
  };
  const handleCancel = async (data) => {
    await cancel(data.comment);
    navigate('/orders', { replace: true });
  };
  const handleFulfill = async (data) => {
    const productsDelivered = Object.keys(data).map((key) => {
      return {
        name: key,
        quantity: data[key],
      };
    });

    //await fulfill(productsDelivered);
    navigate('/orders', { replace: true });
  };

  return (
    <>
      {checkAccess(['sop', 'distributor']) && (
        <AcceptOrder
          open={showAccept}
          onAccept={handleAccept}
          setOpen={(open) => setShowAccept(open)}
        />
      )}
      {checkAccess(['sop', 'distributor']) && (
        <RejectOrder
          open={showReject}
          onReject={handleReject}
          setOpen={(open) => setShowReject(open)}
        />
      )}
      {checkAccess(['sop', 'distributor', 'fsa']) && (
        <CancelOrder
          open={showCancel}
          onCancel={handleCancel}
          setOpen={(open) => setShowCancel(open)}
        />
      )}
      {checkAccess(['fsa', 'sop', 'distributor']) && (
        <FulfillOrder
          open={showFulfill}
          order={order}
          onFulfill={handleFulfill}
          setOpen={(open) => setShowFulfill(open)}
          confirmText="Are you sure you want to fulfill the order?"
          buttonText="Fulfill"
        />
      )}
      <Loader show={loading} />
      <Alert
        message={error && error.message}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
        title="Error"
      />
      <Content>
        {loading || order ? (
          <Card className="grid grid-cols-10">
            <Back to="/orders" className="col-span-3" />
            <div className="col-span-4 flex justify-center items-center text-center text-xl font-bold text-lh-head-black">
              {order && (
                <FontAwesomeIcon
                  icon={
                    order.createdOn === 'chatbot' ? faComment : faUserCircle
                  }
                  className="mr-2 text-lh-head-black"
                />
              )}
              {order && order.id}
            </div>
            {displayEditButton && !checkAccess(['fsm']) && (
              <Edit className="col-span-3 flex-row-reverse" />
            )}
            <h2 className="col-span-8 font-bold">
              {order && order.user.name}
              <p className="col-span-full font-normal text-lh-text-black">
                {order && dayjs(order.createdAt).format('DD.MM.YYYY HH:mm')}
              </p>
            </h2>
            <div className="col-span-2 flex flex-col items-center font-bold text-lg text-lh-head-black">
              <p>Status</p>
              {order &&
                order.status &&
                (order.status === 'pending' ? (
                  <FontAwesomeIcon icon={faClock} className="text-2xl mt-0" />
                ) : order.status === 'accepted' ? (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-2xl mt-0"
                  />
                ) : order.status === 'rejected' ? (
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    className="text-2xl mt-0"
                  />
                ) : order.status === 'fulfilled' ? (
                  <FontAwesomeIcon icon={faTruck} className="text-2xl mt-0" />
                ) : order.status === 'canceled' ? (
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    className="text-2xl mt-0"
                  />
                ) : (
                  ''
                ))}
            </div>
            <hr className="col-span-full mt-2 mb-2" />
            <h2 className="col-span-full font-bold mt-4 mb-2 text-lh-head-black">
              {order &&
                (['accepted', 'fulfilled'].includes(order.status)
                  ? 'Products fulfilled / ordered'
                  : 'Products ordered')}
            </h2>
            <>
              {order?.products &&
                order.products.map((product) => (
                  <Product key={product.id} product={product} />
                ))}
            </>
            <h2 className="col-span-full font-bold mb-2 text-lh-head-black">
              Delivery Address
            </h2>
            <p className="col-span-full rounded-lg mb-4 py-1 px-3 border-2 text-lh-text-black">
              {order && order.address}
            </p>
            {displayComment && (
              <>
                <h2 className="col-span-full font-bold mb-2 text-lh-head-black">
                  Comment
                </h2>
                <p className="col-span-full rounded-lg mb-4 py-1 px-3 border-2 text-lh-text-black">
                  {order && order.comment}
                </p>
              </>
            )}

            {checkAccess(['sop', 'distributor']) && displayAcceptButton && (
              <Button
                onClick={() => setShowAccept(true)}
                color="green"
                type="button"
                className="col-span-full mt-2 py-2"
              >
                Accept
              </Button>
            )}
            {checkAccess(['sop', 'distributor']) &&
              displayRejectButton &&
              !displayCancelButton && (
                <Button
                  onClick={() => setShowReject(true)}
                  color="red"
                  type="button"
                  className="col-span-full mt-2 py-2"
                >
                  Reject
                </Button>
              )}
            {checkAccess(['sop', 'fsa', 'distributor']) &&
              displayCancelButton && (
                <Button
                  onClick={() => setShowCancel(true)}
                  color="red"
                  type="button"
                  className="col-span-full mt-2 py-2"
                >
                  Cancel
                </Button>
              )}
            {checkAccess(['fsa', 'sop', 'distributor']) &&
              displayFulfillButton && (
                <Button
                  onClick={() => setShowFulfill(true)}
                  color="blue"
                  type="button"
                  className="col-span-full mt-2 py-2"
                >
                  Fulfil
                </Button>
              )}
          </Card>
        ) : (
          <Card>Order not found!</Card>
        )}
      </Content>
    </>
  );
};

export default Order;

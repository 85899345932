import React from 'react';
import Select from 'react-select';
import { Controller } from 'react-hook-form';

const SearchableSelect = ({
  label,
  name,
  values,
  control,
  placeholder,
  className,
  rules,
}) => {
  return (
    <div className={className}>
      <label className="font-bold">{label}</label>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { value, onChange, onBlur } }) => {
          return (
            <Select
              options={values}
              placeholder={placeholder}
              onChange={(options) => onChange(options)}
              onBlur={onBlur}
              value={values.filter((option) => value?.value === option.value)}
              defaultValue={values.filter(
                (option) => value?.value === option.value
              )}
            />
          );
        }}
      />
    </div>
  );
};

export default SearchableSelect;

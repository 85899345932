import { doc, getDoc } from 'firebase/firestore';
import { useCallback, useEffect, useMemo } from 'react';
import { firestore } from '../utils/firebase';

export const useCheckPhone = () => {
  const checkPhone = useCallback(async (phone: string): Promise<boolean> => {
    const ref = doc(firestore, 'phones', phone);
    const snap = await getDoc(ref);
    return snap.exists();
  }, []);

  return useMemo(() => checkPhone, [checkPhone]);
};

import clsx from 'clsx';
import { Link } from 'react-router-dom';

type NewProps = {
  to: string;
  text?: string;
};

const New = ({ to, text = '+' }: NewProps) => {
  const styles = clsx(
    'rounded-full h-20 flex items-center justify-center bg-green-500 text-white fixed bottom-5 left-1/2 -ml-10 shadow-md drop-shadow-md',
    text === '+' ? 'w-20 text-5xl' : 'px-5 text-2xl'
  );

  return (
    <Link to={to} className={styles}>
      {text}
    </Link>
  );
};

export default New;

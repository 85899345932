import { FirebaseError } from 'firebase/app';
import { Unsubscribe } from 'firebase/auth';
import {
  collection,
  collectionGroup,
  doc,
  DocumentSnapshot,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  Query,
  where,
} from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import {
  Product,
  Outlet,
  OutletChannel,
  CreateOutletData,
  OutletChanges,
} from '../../../../global';
import { useCheckAccess } from '../auth/use-checkAccess';
import {
  confirmOutletChanges,
  confirmOutletChangesRejection,
  confirmOutletRegistration,
  confirmOutletRejection,
  createOutlet,
  registerOutletChanges,
  rejectOutletChanges,
  rejectOutletRegistration,
} from '../db/outlets';
import { useLoadingValue } from '../utils';
import { docToJSON, firestore } from '../utils/firebase';
import { usePagination } from '../utils/usePagination';
import { useAuth } from './use-auth';
import { getTotalVolume } from '../utils/helpers';

type OutletsHook = {
  error: FirebaseError;
  hasMore: boolean;
  load: () => void;
  loading: boolean;
  reset: () => void;
  outlets: Outlet[];
};

type OutletsHookOptions = {
  pageSize?: number;
  retailerId?: string;
  active?: boolean;
};

export const useOutlets = ({
  active,
  retailerId,
  pageSize = 10,
}: OutletsHookOptions): OutletsHook => {
  const { id } = useAuth();
  const [outletsQuery, setOutletsQuery] = useState<Query>();
  const { error, hasMore, load, loading, reset, values } = usePagination(
    outletsQuery,
    pageSize
  );

  useEffect(() => {
    let newQuery: Query;
    if (id) {
      newQuery = query(
        collection(firestore, 'outlets'),
        where('userIds', 'array-contains', id)
      );
      if (active !== undefined) {
        newQuery = query(newQuery, where('active', '==', active));
      }
      if (retailerId) {
        newQuery = query(newQuery, where('retailer.id', '==', retailerId));
      }
      setOutletsQuery(newQuery);
    }
  }, [id, retailerId, active]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      outlets: values
        ? values.map((v) => prepareOutlet(docToJSON(v) as Outlet))
        : values,
    }),
    [error, hasMore, load, loading, reset, values]
  );
};

type OutletHook = {
  error: FirebaseError;
  loading: boolean;
  reset: () => void;
  update: (changes: OutletChanges) => Promise<void>;
  create: (data: CreateOutletData) => Promise<void>;
  confirmRegistration: (confirmed: boolean, comment?: string) => Promise<void>;
  confirmChanges: (
    confirmed: boolean,
    changes: OutletChanges,
    comment?: string
  ) => Promise<void>;
  confirmRejection: () => Promise<void>;
  confirmChangesRejection: () => Promise<void>;
  outlet: Outlet | null;
};

export const useOutlet = (id?: string): OutletHook => {
  const checkAccess = useCheckAccess();
  const { error, loading, reset, setError, setValue, value, setLoading } =
    useLoadingValue<DocumentSnapshot, FirebaseError>();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (id) {
      if (!loading) setLoading(true);
      const ref = doc(firestore, 'outlets', id);
      unsubscribe = onSnapshot(ref, setValue, setError);
    } else {
      setLoading(false);
    }
    return unsubscribe;
  }, [id]);

  const create = async (data: CreateOutletData) => {
    try {
      setLoading(true);
      await createOutlet(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const update = async (changes: OutletChanges) => {
    if (!id) return;
    try {
      setLoading(true);
      await registerOutletChanges(id, checkAccess(['sop']), changes);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const confirmRegistration = async (confirmed: boolean, comment?: string) => {
    if (!id || !checkAccess(['sop'])) return;
    try {
      setLoading(true);
      confirmed
        ? await confirmOutletRegistration(id)
        : await rejectOutletRegistration(id, comment);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const confirmChanges = async (
    confirmed: boolean,
    changes: OutletChanges,
    comment?: string
  ) => {
    if (!id || !checkAccess(['sop'])) return;
    try {
      setLoading(true);
      confirmed
        ? await confirmOutletChanges(id, changes)
        : await rejectOutletChanges(id, changes, comment);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const confirmRejection = async () => {
    if (!id || !checkAccess(['fsa'])) return;
    try {
      setLoading(true);
      confirmOutletRejection(id);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const confirmChangesRejection = async () => {
    if (!id || !checkAccess(['fsa'])) return;
    try {
      setLoading(true);
      confirmOutletChangesRejection(id);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  useEffect(() => {
    console.log(error);
  }, [error]);

  return useMemo(
    () => ({
      error,
      loading,
      reset,
      update,
      create,
      confirmRegistration,
      confirmChanges,
      confirmRejection,
      confirmChangesRejection,
      outlet: value ? prepareOutlet(docToJSON(value) as Outlet) : null,
    }),
    [error, loading, value]
  );
};

const prepareOutlet = (outlet: Outlet): Outlet => {
  if (!outlet) return null;
  let pendingVolume =
    getTotalVolume(outlet.ordersVolumeAppPending) +
    getTotalVolume(outlet.ordersVolumeChatbotPending);
  let acceptedVolume =
    getTotalVolume(outlet.ordersVolumeChatbotAccepted) +
    getTotalVolume(outlet.ordersVolumeAppAccepted);
  let fulfilledVolume =
    getTotalVolume(outlet.ordersVolumeChatbotFulfilled) +
    getTotalVolume(outlet.ordersVolumeAppFulfilled);
  let fulfilledVolumeChatbot = getTotalVolume(
    outlet.ordersVolumeChatbotFulfilled
  );
  let fulfilledVolumeApp = getTotalVolume(outlet.ordersVolumeAppFulfilled);
  return {
    ...outlet,
    pendingVolume,
    acceptedVolume,
    fulfilledVolume,
    fulfilledVolumeChatbot,
    fulfilledVolumeApp,
    channelName: getChannelName(outlet.channel),
    channelNameChanges: getChannelName(outlet?.changes?.channel),
  };
};

const getChannelName = (channel: OutletChannel): string => {
  if (!channel) return '';
  const channels = {
    blockmaker: 'Blockmaker',
    blockmaker_retailer: 'Blockmaker/Retailer',
    container: 'Container',
    neighborhood_shop: 'Neighborhood Shop',
    palleter: 'Palleter',
    tile_seller: 'Tile Seller',
  };
  return channels[channel];
};

import { useRef } from 'react';
import ReactDOM from 'react-dom';
import { useOnClickOutside } from '../../lib/hooks/use-onclickoutside';

type ModalProps = {
  open: boolean;
  setOpen: (boolean) => void;
  children: JSX.Element | JSX.Element[] | string;
  block?: boolean;
};

const Modal = ({ open, setOpen, block, children }: ModalProps) => {
  const node = useRef<HTMLDivElement>(null);

  useOnClickOutside(node, () => setOpen(false), block);

  return open
    ? ReactDOM.createPortal(
        <div
          className="fixed z-50 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div
              ref={node}
              className="w-full md:min-w-min md:w-1/2 inline-block align-bottom bg-white rounded-3xl px-6 py-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            >
              <button
                onClick={() => setOpen(false)}
                className="absolute top-4 right-8 font-bold"
              >
                X
              </button>
              {children}
            </div>
          </div>
        </div>,
        document.body
      )
    : null;
};

export default Modal;

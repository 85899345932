import { useOrders, useOrdersTypesense } from '../../lib/hooks/use-orders';
import Loader from '../../components/ui/Loader';
import SearchAndSort from '../../components/ui/SearchAndSort';
import React, { useEffect, useState } from 'react';
import useStore from '../../lib/hooks/use-store';
import Content from '../../components/layout/Content';
import TopBar from '../../components/layout/TopBar';
import ButtonBottomRight from '../../components/ui/ButtonBottomRight';
import Order from '../../components/orders/Order';
import List from '../../components/ui/list/List';
import Alert from '../../components/ui/Alert';
import { useCheckAccess } from '../../lib/auth/use-checkAccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faSync,
} from '@fortawesome/free-solid-svg-icons';
import New from '../../components/ui/New';
import { useAssociates } from '../../lib/hooks/use-associates';
import { useAuth } from '../../lib/hooks/use-auth';

const Orders = React.memo(() => {
  const checkAccess = useCheckAccess();
  const { typesenseKeyUserIds } = useAuth();
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [state, dispatch] = useStore();
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertFSA, setShowAlertFSA] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [searchEnabled, setSearchEnabled] = useState(false);

  const { error, orders, loading, load, hasMore, reset } = useOrders({
    status: state.status,
    direction,
    fsaId: state.selectedFSAOrders ? state.selectedFSAOrders.value : null,
  });
  const {
    error: errorTypesense,
    hasMore: hasMoreTypesense,
    load: loadTypesense,
    loading: loadingTypesense,
    orders: ordersTypesense,
    reset: resetTypesense,
  } = useOrdersTypesense({
    status: state.status,
    direction,
    searchText: searchText,
  });

  const {
    error: fsaError,
    loading: fsaLoading,
    associates: fsas,
  } = useAssociates({ roles: 'fsa', pageSize: 0 });

  useEffect(() => {
    if (error) {
      setShowAlert(true);
    } else if (showAlert) {
      setShowAlert(false);
    }
    return () => {
      setShowAlert(false);
    };
  }, [error]);

  useEffect(() => {
    if (typesenseKeyUserIds) {
      setSearchEnabled(true);
    }
  });

  useEffect(() => {
    if (fsaError) {
      setShowAlertFSA(true);
    } else if (showAlertFSA) {
      setShowAlertFSA(false);
    }
    return () => {
      setShowAlertFSA(false);
    };
  }, [fsaError]);

  useEffect(() => {
    if (
      state.status.includes('fulfilled') ||
      state.status.includes('rejected') ||
      state.status.includes('canceled')
    ) {
      setDirection('desc');
    } else {
      setDirection('asc');
    }
  }, [state.status]);

  const onCategoryChange = (event) => {
    if (event.target.value === 'all') {
      dispatch('SET_STATUS', ['rejected', 'fulfilled', 'canceled']);
    } else {
      dispatch('SET_STATUS', [event.target.value]);
    }
  };

  const handleStatusChange = (newStatus) => () => {
    dispatch('SET_STATUS', newStatus);
  };

  const handleDirectionChange = () => {
    if (direction === 'asc') {
      setDirection('desc');
    } else {
      setDirection('asc');
    }
  };

  const handleSelectedCategory = () => {
    if (
      !state.status.includes('rejected') &&
      !state.status.includes('canceled')
    )
      return 'fulfilled';
    if (
      !state.status.includes('fulfilled') &&
      !state.status.includes('canceled')
    )
      return 'rejected';
    if (
      !state.status.includes('fulfilled') &&
      !state.status.includes('rejected')
    )
      return 'canceled';
    return '';
  };

  useEffect(() => {
    let newFSAs = [];
    if (fsas) {
      newFSAs = fsas.map((fsa) => ({
        label: fsa.name,
        value: fsa.id,
      }));
    }
    newFSAs.unshift({ label: 'All', value: null } as any);
  }, [fsas]);

  return (
    <Content topBar>
      <ButtonBottomRight
        onClick={reset}
        blocked={loading}
        arialabel="reload"
        icon={faSync}
      />
      <button
        onClick={handleDirectionChange}
        className="rounded-full flex justify-center items-center h-8 w-8 fixed top-5 right-20 text-xl z-30"
      >
        <FontAwesomeIcon
          icon={direction === 'asc' ? faAngleDoubleUp : faAngleDoubleDown}
        />
      </button>
      <Loader show={(loading && orders.length === 0) || fsaLoading} />
      <Alert
        message={error && error.message}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
        title="Error"
      />
      <Alert
        message={fsaError && fsaError.message}
        open={showAlertFSA}
        setOpen={(open) => setShowAlertFSA(open)}
        title="Error"
      />
      <TopBar>
        <div
          className={
            'flex justify-center content-center flex-col text-center flex-grow cursor-pointer text-lh-dark-blue text-xl font-medium border-b-2 border-lh-dark-blue h-full' +
            (state.status.includes('pending')
              ? ' border-b-4 border-lh-dark-blue font-semibold'
              : '')
          }
          onClick={handleStatusChange(['pending'])}
        >
          Pending
        </div>
        <div
          className={
            'flex justify-center content-center flex-col text-center flex-grow cursor-pointer text-lh-dark-blue text-xl font-medium border-b-2 border-lh-dark-blue h-full' +
            (state.status.includes('accepted')
              ? ' border-b-4 border-lh-dark-blue font-semibold'
              : '')
          }
          onClick={handleStatusChange(['accepted'])}
        >
          Accepted
        </div>
        <div
          className={
            'flex justify-center content-center flex-col text-center flex-grow cursor-pointer text-lh-dark-blue text-xl font-medium border-b-2 border-lh-dark-blue h-full' +
            (state.status.includes('fulfilled') ||
            state.status.includes('rejected') ||
            state.status.includes('canceled')
              ? ' border-b-4 border-lh-dark-blue font-semibold'
              : '')
          }
          onClick={handleStatusChange(['fulfilled', 'rejected', 'canceled'])}
        >
          Archive
        </div>
      </TopBar>
      {(state.status.includes('rejected') ||
        state.status.includes('fulfilled') ||
        state.status.includes('canceled')) && (
        <SearchAndSort
          onCategoryChange={onCategoryChange}
          selectedCategory={handleSelectedCategory()}
          categories={{
            all: 'All',
            rejected: 'Rejected',
            fulfilled: 'Fulfilled',
            canceled: 'Canceled',
          }}
          displayCategory={
            state.status.includes('rejected') ||
            state.status.includes('fulfilled') ||
            state.status.includes('canceled')
              ? true
              : false
          }
          className="mt-4"
        />
      )}
      <SearchAndSort
        onCategoryChange={onCategoryChange}
        selectedCategory={state.retailerStatus}
        categories={{}}
        displayCategory={false}
        displaySearch={true}
        searchPlaceHolder="Outlet, retailer or fsa name"
        className={'mt-4'}
        setSearchText={setSearchText}
        searchDisabled={!searchEnabled}
      />
      {(!searchText || (searchText && searchText.length < 3)) && (
        <List load={load} hasMore={hasMore} loading={loading}>
          {orders && orders.length > 0 ? (
            orders.map((order) => <Order order={order} key={order.id} />)
          ) : (
            <p className="justify-between mx-2 mb-2 h-18 list-none shadow-md rounded-lg pb-4 px-2 pt-2 bg-white text-center">
              No {state.status.join(' / ')} orders.
            </p>
          )}
        </List>
      )}
      {searchText && searchText.length >= 3 && (
        <>
          <Loader show={loadingTypesense} />
          <List
            load={loadTypesense}
            hasMore={hasMoreTypesense}
            loading={loadingTypesense}
          >
            {ordersTypesense &&
              ordersTypesense.map((order) => (
                <Order order={order} key={order.id} />
              ))}
          </List>
        </>
      )}
      {checkAccess(['fsa', 'sop']) && <New to="new" />}
    </Content>
  );
});

export default Orders;

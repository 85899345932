import { useNavigate } from 'react-router-dom';
import { NotificationType } from '../../../../global';
import { useNotification } from '../../lib/hooks/use-notifiactions';

type NotificationProps = {
  id: string;
  action: string;
  createdAt: number;
  formattedCreatedAt: string;
  message: string;
  title: string;
  type: NotificationType;
  read: boolean;
};

export const Notification = ({
  id,
  action,
  createdAt,
  formattedCreatedAt,
  message,
  title,
  type,
  read,
}: NotificationProps) => {
  const navigate = useNavigate();
  const { markAsRead } = useNotification(id);

  const handleClick = () => {
    markAsRead();
    navigate(action);
  };

  return (
    <li
      className="flex flex-col mx-2 mb-2 h-18 list-none shadow-md rounded-lg pb-4 px-2 pt-2 cursor-pointer bg-white border-lh-dark-blue border-2"
      onClick={handleClick}
      key={id}
    >
      <div className="flex justify-between">
        <h2 className="font-semibold">{title}</h2>
        <p className="font-light">{formattedCreatedAt}</p>
      </div>
      <p className="text-sm">{message}</p>
    </li>
  );
};

import React, { useEffect, useState } from 'react';
import Content from '../../components/layout/Content';
import { useNavigate } from 'react-router-dom';
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import { firestore } from '../../lib/utils/firebase';
import { addDoc, collection, getDocs } from 'firebase/firestore';

type Props = {};

export default function NewProduct({}: Props) {
  const navigate = useNavigate();
  const storage = getStorage();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState(0);
  const [file, setFile] = useState(null as any);
  const [categories, setCategories] = useState([] as any[]);
  const [category, setCategory] = useState('');
  const [error, setError] = useState('');

  // load categories from firestore async
  useEffect(() => {
    async function loadCategories() {
      // get categories from firestore
      const categoriesQuery = collection(firestore, 'categories');
      await getDocs(categoriesQuery)
        .then((snapshot) => {
          let categories = [];
          snapshot.docs.map((doc) => {
            const category = {
              id: doc.id,
              name: doc.data().name,
            };
            categories.push(category);
          });
          //sort categories by name
          categories.sort((a, b) => (a.name > b.name ? 1 : -1));
          setCategories(categories);
        })
        .catch((error) => {
          console.log('ERROR:', error);
        });
    }
    loadCategories();
  }, []);

  function handleSubmit() {
    if (category === '' || category === 'All') {
      setError('Please select a category');
    } else if (!name) {
      setError('Please enter a name');
    } else if (!description) {
      setError('Please enter a description');
    } else if (!price || price === 0) {
      setError('Please enter a price');
    } else if (!file) {
      setError('Please select an image');
    } else {
      const imageName = new Date().getTime();
      const storageRef = ref(storage, `/products/${name + imageName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Handle progress updates
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload progress: ${progress}%`);
        },
        (error) => {
          // Handle upload errors
          console.error('Upload error:', error);
        },
        () => {
          // Handle successful upload
          console.log('Upload complete!');
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('File available at', downloadURL);

            const productsRef = collection(firestore, 'products');
            addDoc(productsRef, {
              name: name,
              description: description,
              price: price,
              img: downloadURL,
              imgName: name + imageName,
              category: category,
            })
              .then(() => {
                // navigate to products page
                navigate('../products');
              })
              .catch((error) => {
                console.log('Error adding document:', error);
              });
          });
        }
      );
    }
  }

  // Use the function to handle file input changes
  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    setFile(file);
  };

  return (
    <Content>
      <div className="text-2xl font-bold text-left">New Product</div>
      <div className="w-11/12 md:w-1/3 mt-4">
        <div>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            id="name"
            className="w-full border"
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
        <div>
          <label htmlFor="description">Description</label>
          <textarea
            name="description"
            id="description"
            className="w-full border h-24"
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </div>
        <div>
          <label htmlFor="category">Category</label>
          <select
            name="category"
            id="category"
            className="w-full border"
            onChange={(e) => {
              console.log('e.target.value:', e.target.value);
              setCategory(e.target.value);
            }}
          >
            {categories.map((category) => {
              return (
                <option key={category.id} value={category.name}>
                  {category.name}
                </option>
              );
            })}
          </select>
        </div>

        <div>
          <label htmlFor="price">Price</label>
          <input
            type="number"
            name="price"
            id="price"
            className="w-full border"
            onChange={(e) => {
              setPrice(parseInt(e.target.value));
            }}
          />
        </div>
        <div>
          <label htmlFor="image">Image</label>
          <input
            type="file"
            accept="image/*"
            name="image"
            id="image"
            className="w-full border"
            onChange={handleFileInputChange}
          />
        </div>
        {file && (
          <>
            <img
              src={URL.createObjectURL(file)}
              alt=""
              className="w-48 shadow"
            />
          </>
        )}

        <div className="text-red-500 mt-4">
          <p>{error}</p>
        </div>

        <div className="flex justify-between mt-4">
          <button
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => {
              navigate('../products');
            }}
          >
            Cancel
          </button>

          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </Content>
  );
}

import { useEffect, useState } from 'react';
import Loader from '../../components/ui/Loader';
import SearchAndSort from '../../components/ui/SearchAndSort';
import Content from '../../components/layout/Content';
import TopBar from '../../components/layout/TopBar';
import List from '../../components/ui/list/List';
import useStore from '../../lib/hooks/use-store';
import { useCheckAccess } from '../../lib/auth/use-checkAccess';
import { useAuth } from '../../lib/hooks/use-auth';
import ChatbotUser from '../../components/retailer/ChatbotUser';
import Alert from '../../components/ui/Alert';
import { useChatbotUsers } from '../../lib/hooks/use-chatbotUsers';

const Retailers = () => {
  const checkAccess = useCheckAccess();
  const { typesenseKey } = useAuth();

  const [state, dispatch] = useStore();
  const [searchText, setSearchText] = useState(null);
  const [searchEnabled, setSearchEnabled] = useState(false);

  const { chatbotUsers, load, loading, hasMore, error } = useChatbotUsers({});

  const [showAlert, setShowAlert] = useState<boolean>(false);

  useEffect(() => {
    if (error) {
      setShowAlert(true);
    } else if (showAlert) {
      setShowAlert(false);
    }
    return () => {
      setShowAlert(false);
    };
  }, [error]);

  const onCategoryChange = (event) => {
    dispatch('SET_RETAILER_STATUS', event.target.value);
  };

  return (
    <Content topBar>
      <Alert
        title="Error"
        message={error && error.message}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
      />
      <TopBar>
        <SearchAndSort
          onCategoryChange={onCategoryChange}
          selectedCategory={state.retailerStatus}
          categories={{
            all: 'All',
            actionRequired: 'Action required',
            noWhatsApp: 'No WhatsApp',
            enabledWhatsApp: 'WhatsApp enabled',
            phoneChanged: 'Phone Nr changed',
          }}
          displayCategory={false}
          displaySearch={false}
          searchPlaceHolder="Retailer name"
          className={'mt-4'}
          setSearchText={setSearchText}
          searchDisabled={!searchEnabled}
        />
      </TopBar>

      <Loader show={loading} />
      <List load={load} hasMore={hasMore} loading={loading}>
        {chatbotUsers &&
          chatbotUsers.map((chatbotUser) => (
            <ChatbotUser chatbotUser={chatbotUser} key={chatbotUser.id} />
          ))}
      </List>
    </Content>
  );
};

export default Retailers;

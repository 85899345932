import { useState } from 'react';
import { useEffect } from 'react';
import {
  ErrorOption,
  Path,
  RegisterOptions,
  UseFormReturn,
} from 'react-hook-form';
import { InputType } from '../../../../global';

export interface InputProps<T>
  extends Partial<Pick<UseFormReturn<T>, 'register' | 'formState'>> {
  name: Path<T>;
  type?: InputType;
  options?: RegisterOptions;
  className?: string;
  rules?: any;
  placeholder?: string;
  value?: string;
  error?: ErrorOption;
  disabled?: boolean;
}

const Input = <T,>({
  name,
  type,
  options,
  register,
  className,
  value,
  error,
  disabled,
  ...rest
}: InputProps<T>) => {
  const [errorCSS, setErrorCSS] = useState<string>('');

  useEffect(() => {
    if (error) {
      setErrorCSS('border-red-500 bg-red-200 focus:ring-red-500');
    } else {
      setErrorCSS('');
    }
  }, [error]);

  return (
    <div className={`mb-4 ${className}`}>
      <input
        disabled={disabled}
        className={`bg-gray-200 rounded-md py-1 px-3 border-2 disabled:opacity-50 w-full ${errorCSS}`}
        type={type}
        {...register(name, options)}
        {...rest}
        value={value}
      />
      {error && <p className="pl-2 text-sm text-red-500">{error.message}</p>}
    </div>
  );
};

export default Input;

import { Timestamp } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { RegisterOptions, SubmitHandler, useForm } from 'react-hook-form';
import {
  FulfillOrderData,
  Order,
  Product as ProductType,
} from '../../../../global';
import { createTier2Fulfillment } from '../../lib/db/tier2fulfillments';
import { useOutlets } from '../../lib/hooks/use-outlets';
import { Confirm } from '../forms/Confirm';
import Input from '../forms/Input';
import SearchableSelect from '../forms/SearchableSelect';
import Alert from '../ui/Alert';
import Button from '../ui/Button';
import Loader from '../ui/Loader';
import Modal from '../ui/Modal';
import Product from './Product';
import ProductDelivered from './ProductDelivered';

type FulfillOrderProps = {
  open: boolean;
  order: Order;
  onFulfill?: SubmitHandler<FulfillOrderForm>;
  onFulfillTier2?: SubmitHandler<FulfillOrderForm>;
  setOpen: (boolean) => void;
  confirmText: string;
  buttonText: string;
  tier2?: boolean;
};

type FulfillOrderForm = {};

const FulfillOrder = ({
  open,
  order,
  onFulfill,
  onFulfillTier2,
  setOpen,
  confirmText,
  buttonText,
  tier2,
}: FulfillOrderProps) => {
  const { register, handleSubmit, reset, getValues, control } = useForm();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [block, setBlock] = useState(false);
  const [showOutletsAlert, setShowOutletsAlert] = useState(false);

  const {
    error: outletsError,
    loading: outletsLoading,
    outlets,
  } = useOutlets({
    pageSize: 0,
    active: true,
  });

  /*
  const moreThanZero = (): boolean => {
    const classic = getValues('classic');
    const supafixc0 = getValues('supafixc0');
    const supafixc1 = getValues('supafixc1');
    const supaset = getValues('supaset');
    return (
      (isNaN(classic) ? 0 : classic) +
        (isNaN(supafixc0) ? 0 : supafixc0) +
        (isNaN(supafixc1) ? 0 : supafixc1) +
        (isNaN(supaset) ? 0 : supaset) >=
      0
    );
  };
  */

  const handleOnConfirm = () => {
    setBlock(false);
    setShowConfirmation(false);

    handleSubmit(onFulfill)();
  };

  const handleOnCancel = () => {
    setBlock(false);
    setShowConfirmation(false);
  };

  useEffect(() => {
    if (order) reset({});
  }, [order, reset]);

  return (
    <Modal open={open} setOpen={setOpen} block={block}>
      <Loader show={outletsLoading} />
      <Alert
        message={outletsError && outletsError.message}
        open={showOutletsAlert}
        setOpen={(open) => setShowOutletsAlert(open)}
        title="Error"
      />
      <Confirm
        title={confirmText}
        open={showConfirmation}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        setOpen={(open) => {
          setShowConfirmation(open);
          setBlock(open);
        }}
      />
      <form
        name="fulfillOrderForm"
        onSubmit={handleSubmit(onFulfill)}
        className="grid grid-cols-12"
      >
        <h2 className="col-span-full font-bold">
          Products fulfilled / ordered
        </h2>
        <>
          {order?.products &&
            order.products.map((product) => (
              <Product key={product.id} product={product} />
            ))}
        </>
        <h2 className="col-span-full font-bold">Products Delivered</h2>
        <>
          {order?.products &&
            order.products.map((product, index) => (
              <ProductDelivered
                key={product.id}
                product={product}
                register={register}
                index={index}
              />
            ))}
        </>
      </form>
      <Button
        text={buttonText}
        color="green"
        className="w-full"
        onClick={() => {
          setBlock(true);
          setShowConfirmation(true);
        }}
      />
    </Modal>
  );
};

export default FulfillOrder;

import dayjs from 'dayjs';
import { initStore } from '../hooks/use-store';

const configureDateStore = () => {
  const actions = {
    SET_DATE: (curState, date: dayjs.Dayjs) => {
      return { date };
    },
    ADD_MONTH: (curState) => {
      return { date: curState.date.add(1, 'month') };
    },
    SUBTRACT_MONTH: (curState) => {
      return { date: curState.date.subtract(1, 'month') };
    },
  };
  //TODO: change this before May next year!!!
  initStore(actions, { date: (dayjs().startOf('month').month() === 3 ? dayjs().add(1, 'month').startOf('month') : dayjs().startOf('month')) });
};

export default configureDateStore;

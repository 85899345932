import { FirebaseError } from 'firebase/app';
import {
  getDocs,
  limit,
  query,
  Query,
  QueryDocumentSnapshot,
  QuerySnapshot,
  startAfter,
} from 'firebase/firestore';
import { useCallback, useEffect, useMemo, useReducer } from 'react';

type ReducerState = {
  error?: FirebaseError;
  loading: boolean;
  values: any[];
  hasMore: boolean;
  lastDoc?: QueryDocumentSnapshot;
  query?: Query;
};

type ErrorAction = { type: 'error'; error: FirebaseError };
type ResetAction = { type: 'reset' };
type ValuesAction = {
  type: 'first' | 'more';
  values: any[];
  hasMore: boolean;
  lastDoc: QueryDocumentSnapshot;
};
type LoadingAction = { type: 'loading'; loading: boolean };
type QueryAction = { type: 'query'; query: Query | undefined };

type ReducerAction =
  | ErrorAction
  | ResetAction
  | ValuesAction
  | LoadingAction
  | QueryAction;

type PaginationHook = {
  error: FirebaseError;
  hasMore: boolean;
  load: () => void;
  loading: boolean;
  reset: () => void;
  values: any[];
};

const defaultState = () => {
  return { loading: true, hasMore: false, values: [] };
};

const reducer =
  () =>
  (state: ReducerState, action: ReducerAction): ReducerState => {
    switch (action.type) {
      case 'error':
        return {
          ...state,
          error: action.error,
          loading: false,
          values: undefined,
          hasMore: false,
          lastDoc: undefined,
        };
      case 'reset':
        return defaultState();
      case 'first': {
        return {
          ...state,
          error: undefined,
          hasMore: action.hasMore,
          loading: false,
          values: [...action.values],
          lastDoc: action.lastDoc,
        };
      }
      case 'more': {
        return {
          ...state,
          error: undefined,
          hasMore: action.hasMore,
          loading: false,
          values: [...state.values, ...action.values],
          lastDoc: action.lastDoc,
        };
      }
      case 'loading': {
        return {
          ...state,
          error: undefined,
          loading: action.loading,
        };
      }
      case 'query': {
        return {
          ...state,
          query: action.query,
        };
      }
      default:
        return state;
    }
  };

export const usePagination = <T>(q: Query<T>, pageSize): PaginationHook => {
  const [state, dispatch] = useReducer(reducer(), defaultState());

  useEffect(() => {
    reset();
  }, [q, pageSize]);

  useEffect(() => {
    if (!state.query) {
      return;
    }
    getDocs(state.query)
      .then((snap) => setFirst(snap, pageSize))
      .catch(setError);
  }, [state.query, pageSize]);

  const reset = () => {
    dispatch({ type: 'reset' });
    if (!q) {
      return;
    }
    let newQuery = q;
    if (pageSize > 0) {
      newQuery = query(q, limit(pageSize));
    }
    dispatch({ type: 'query', query: newQuery });
  };

  const setFirst = (snap: QuerySnapshot, pageSize: number): void => {
    dispatch({
      type: 'first',
      values: snap?.docs ? snap.docs : [],
      lastDoc: snap?.docs[snap.size - 1],
      hasMore: snap?.size !== 0 && snap?.size === pageSize,
    });
  };

  const setMore = (snap: QuerySnapshot, pageSize: number): void => {
    dispatch({
      type: 'more',
      values: snap?.docs ? snap.docs : [],
      lastDoc: snap?.docs[snap.size - 1],
      hasMore: snap?.size !== 0 && snap?.size === pageSize,
    });
  };

  const setError = (error: FirebaseError): void => {
    console.log('er', error);
    dispatch({ type: 'error', error });
  };

  const load = useCallback(() => {
    dispatch({ type: 'loading', loading: true });
    if (!state.query || !state.lastDoc || !state.hasMore) {
      dispatch({ type: 'loading', loading: false });
      return;
    }
    const refMore = query(state.query, startAfter(state.lastDoc));
    getDocs(refMore)
      .then((snap) => setMore(snap, pageSize))
      .catch(setError);
  }, [state.hasMore, state.lastDoc, pageSize, state.query]);

  return useMemo(
    () => ({
      error: state.error,
      hasMore: state.hasMore,
      load,
      loading: state.loading,
      reset,
      values: state.values,
    }),
    [state.error, state.hasMore, state.loading, state.values, load]
  );
};

import React, { useEffect, useState } from 'react';
import Content from '../../../components/layout/Content';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../../lib/utils/firebase';
import { Product } from '../../../../../global';

type Props = {};

type ProductParams = 'id';

export default function ProductOverview({}: Props) {
  const [product, setProduct] = useState({} as Product);
  // get product from firestore by id in url
  // display product details
  const { id } = useParams<ProductParams>();

  // load products from firestore async
  useEffect(() => {
    if (!id) return;
    async function loadProduct() {
      const productRef = doc(firestore, `products/${id}`);
      await getDoc(productRef)
        .then((snapshot) => {
          const product: Product = {
            name: snapshot.data().name,
            description: snapshot.data().description,
            price: snapshot.data().price,
            img: snapshot.data().img,
          };
          setProduct(product);
        })
        .catch((error) => {
          console.log('ERROR:', error);
        });
    }
    loadProduct();
  }, [id]);

  return (
    <>
      <Content>
        {product && (
          <div className="flex flex-col items-center justify-center">
            <img src={product.img} alt={product.name} className="w-48" />
            <h1 className="text-2xl font-bold">{product.name}</h1>
            <p className="text-xl">{product.description}</p>
            <p className="text-xl">Price: ₦{product.price}</p>
          </div>
        )}
      </Content>
    </>
  );
}

import {
  faClock,
  faComment,
  faUserCircle,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { RegisterOptions, SubmitHandler, useForm } from 'react-hook-form';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Confirm } from '../../../components/forms/Confirm';
import Input from '../../../components/forms/Input';
import SearchableSelect from '../../../components/forms/SearchableSelect';
import Textarea from '../../../components/forms/Textarea';
import Content from '../../../components/layout/Content';
import Alert from '../../../components/ui/Alert';
import Back from '../../../components/ui/Back';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import Loader from '../../../components/ui/Loader';
import { useAssociates } from '../../../lib/hooks/use-associates';
import { useOrder } from '../../../lib/hooks/use-orders';
import { useUser } from '../../../lib/hooks/use-user';

type EditOrderParams = 'id';

type EditOrderFormValues = {
  distributor: {
    value: string;
    label: string;
  };
  classic: number;
  supaset: number;
  supafixc0: number;
  supafixc1: number;
  comment: string;
  address: string;
  userIds: string[];
};

const EditOrder = () => {
  const { id } = useParams<EditOrderParams>();
  const navigate = useNavigate();
  const {
    register,
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
    getValues,
    control,
  } = useForm<EditOrderFormValues>();
  const {
    order,
    error: orderError,
    update,
    loading: orderLoading,
  } = useOrder(id);
  const [showComment, setShowComment] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const {
    error: distributorError,
    loading: distributorLoading,
    associates: distributors,
  } = useAssociates({ roles: 'distributor', pageSize: 0 });
  const [showOrderAlert, setShowOrderAlert] = useState(false);
  const [showDistributorAlert, setShowDistributorAlert] = useState(false);
  const { user } = useUser();

  useEffect(() => {
    if (orderError) {
      setShowOrderAlert(true);
    } else if (showOrderAlert) {
      setShowOrderAlert(false);
    }
    return () => {
      setShowOrderAlert(false);
    };
  }, [orderError]);

  useEffect(() => {
    if (distributorError) {
      setShowDistributorAlert(true);
    } else if (showDistributorAlert) {
      setShowDistributorAlert(false);
    }
    return () => {
      setShowDistributorAlert(false);
    };
  }, [distributorError]);

  useEffect(() => {
    if (order) {
      reset({
        address: order.address,
        comment: order.comment,
        distributor: {
          value: order.distributor.id,
          label: order.distributor.name,
        },
        ...order.products,
      });
    }
    if (order?.comment) {
      setShowComment(true);
    }
  }, [order, reset]);

  useEffect(() => {
    if (isSubmitSuccessful && !orderLoading) {
      navigate(`/orders/${id}`);
    }
  }, [isSubmitSuccessful, orderLoading]);

  const moreThanZero = (): boolean => {
    const classic = getValues('classic');
    const supafixc0 = getValues('supafixc0');
    const supafixc1 = getValues('supafixc1');
    const supaset = getValues('supaset');
    return (
      (isNaN(classic) ? 0 : classic) +
        (isNaN(supafixc0) ? 0 : supafixc0) +
        (isNaN(supafixc1) ? 0 : supafixc1) +
        (isNaN(supaset) ? 0 : supaset) >
      0
    );
  };

  const productOptions: RegisterOptions = {
    valueAsNumber: true,
    min: {
      value: 0,
      message: 'Product cannot be negative!',
    },
    max: {
      value: 10000,
      message: "You can only order 10'000 bags at a time!",
    },
    validate: {
      moreThanZero,
    },
  };
  const addressOptions: RegisterOptions = {
    required: 'Please enter an address...',
    maxLength: {
      value: 100,
      message: 'Address cannot be more than 100 characters!',
    },
  };

  const onSubmit: SubmitHandler<EditOrderFormValues> = async (data) => {
    const distributor = user.roles.includes('distributor')
      ? { id: user.id, name: user.name, phone: user.phone }
      : distributors.find((d) => d.id === data.distributor.value);

    await update({
      products: {
        classic: isNaN(data.classic) ? 0 : data.classic,
        supaset: isNaN(data.supaset) ? 0 : data.supaset,
        supafixc0: isNaN(data.supafixc0) ? 0 : data.supafixc0,
        supafixc1: isNaN(data.supafixc1) ? 0 : data.supafixc1,
      },
      address: data.address,
      distributor: {
        id: distributor.id,
        name: distributor.name,
        phone: distributor.phone,
      },
      userIds: [distributor.id, order.fsa.id, order.retailer.id, order.sop.id],
    });
  };

  return (
    <>
      <Alert
        message={orderError && orderError.message}
        open={showOrderAlert}
        setOpen={(open) => setShowOrderAlert(open)}
        title="Error"
      />
      <Alert
        message={distributorError && distributorError.message}
        open={showDistributorAlert}
        setOpen={(open) => setShowDistributorAlert(open)}
        title="Error"
      />
      <Loader show={orderLoading || distributorLoading} />
      <Confirm
        title="Are you sure you want to save the changes?"
        open={showConfirmation}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={() => setShowConfirmation(false)}
        setOpen={(open) => setShowConfirmation(open)}
      />
      {order ? (
        order.status === 'pending' ? (
          <Content>
            <Card className="grid grid-cols-10">
              <Back to={`/orders/${id}`} className="col-span-3" />
              <div className="col-span-4 flex justify-center items-center text-center text-xl font-bold text-lh-head-black">
                {order && (
                  <FontAwesomeIcon
                    icon={
                      order.createdOn === 'chatbot' ? faComment : faUserCircle
                    }
                    className="mr-2 text-lh-head-black"
                  />
                )}{' '}
                {order.id}
              </div>
              <h2 className="col-span-8 font-bold">
                {order && order.outlet.name}
                <p className="col-span-full font-normal text-lh-text-black">
                  {order && dayjs(order.createdAt).format('DD.MM.YYYY HH:mm')}
                </p>
              </h2>
              <div className="col-span-2 flex flex-col items-center font-bold text-lg text-lh-head-black">
                <p>Status</p>
                <FontAwesomeIcon icon={faClock} className="text-2xl mt-0" />
              </div>
              <hr className="col-span-full mt-2 mb-2" />

              <form
                name="Edit Order"
                className="grid grid-cols-10 col-span-full"
              >
                <SearchableSelect
                  control={control}
                  name="distributor"
                  rules={{ required: true }}
                  label="Distributor"
                  placeholder="Select Distributor..."
                  values={
                    distributors
                      ? distributors.map((distributor) => ({
                          label: distributor.name,
                          value: distributor.id,
                        }))
                      : []
                  }
                  className="col-span-12 text-lh-text-black"
                />
                <h2 className="font-bold col-span-10 mt-4 mb-2 text-lh-head-black">
                  Products Ordered
                </h2>
                <label
                  htmlFor="classic"
                  className="col-span-6 pt-1 text-lh-text-black"
                >
                  Classic
                </label>
                <Input
                  register={register}
                  placeholder="0"
                  name="classic"
                  type="number"
                  options={productOptions}
                  className="col-span-3 text-right text-lh-text-black mr-3"
                />
                <label
                  htmlFor="classic"
                  className="col-span-1 py-1 text-gray-500 font-thin"
                >
                  bags
                </label>
                <label
                  htmlFor="supaset"
                  className="col-span-6 pt-1 text-lh-text-black"
                >
                  Supaset
                </label>
                <Input
                  register={register}
                  placeholder="0"
                  name="supaset"
                  type="number"
                  options={productOptions}
                  className="col-span-3 text-right text-lh-text-black mr-3"
                />
                <label
                  htmlFor="classic"
                  className="col-span-1 py-1 text-gray-500 font-thin"
                >
                  bags
                </label>
                <label
                  htmlFor="supafixc0"
                  className="col-span-6 pt-1 text-lh-text-black"
                >
                  Supafix C0
                </label>
                <Input
                  register={register}
                  placeholder="0"
                  name="supafixc0"
                  type="number"
                  options={productOptions}
                  className="col-span-3 text-right text-lh-text-black mr-3"
                />
                <label
                  htmlFor="classic"
                  className="col-span-1 py-1 text-gray-500 font-thin"
                >
                  bags
                </label>
                <label
                  htmlFor="supafixc1"
                  className="col-span-6 pt-1 text-lh-text-black"
                >
                  Supafix C1
                </label>
                <Input
                  register={register}
                  placeholder="0"
                  name="supafixc1"
                  type="number"
                  options={productOptions}
                  className="col-span-3 text-right text-lh-text-black mr-3"
                />
                <label
                  htmlFor="classic"
                  className="col-span-1 py-1 text-gray-500 font-thin"
                >
                  bags
                </label>
                <h2 className="font-bold col-span-10 mb-2 text-lh-head-black">
                  Delivery Address
                </h2>
                <Input
                  register={register}
                  placeholder="Address..."
                  name="address"
                  type="text"
                  options={addressOptions}
                  className="col-span-10 text-left text-lh-text-black"
                />
                {showComment && (
                  <>
                    <label
                      htmlFor="comment"
                      className="font-bold col-span-full mb-2 text-lh-head-black"
                    >
                      Comment
                    </label>
                    <Textarea
                      register={register}
                      name="comment"
                      cols={30}
                      rows={5}
                      className="col-span-full text-lh-text-black"
                    />
                  </>
                )}
              </form>
              <Button
                text="Save changes"
                color="green"
                className="col-span-10"
                onClick={() => setShowConfirmation(true)}
              />
            </Card>
          </Content>
        ) : (
          <Navigate to={`/orders/${id}`} replace />
        )
      ) : (
        <Card>
          <p>Order not found!</p>
        </Card>
      )}
    </>
  );
};

export default EditOrder;

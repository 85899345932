import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavigateOptions, To, useNavigate } from 'react-router-dom';

type BackProps = {
  to: To;
  options?: NavigateOptions;
  className?: string;
};

const Back = ({ className, to, options }: BackProps) => {
  const navigate = useNavigate();
  return (
    <div className={`flex my-3 mr-2 ${className}`}>
      <button onClick={() => navigate(to, options)} className="pl-3 pr-4 py-2 ">
        <FontAwesomeIcon
          icon={faChevronCircleLeft}
          className="text-xl text-lh-dark-blue"
        />
      </button>
    </div>
  );
};

export default Back;

import {
  Auth,
  AuthError,
  ConfirmationResult,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { useMemo, useState } from 'react';
import { useCheckPhone } from '../hooks/use-checkphone';
import { firestore } from '../utils/firebase';
import { PhoneActionHook } from './types';

export const useSignInWithPhone = (auth: Auth): PhoneActionHook => {
  const [error, setError] = useState<AuthError>();
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmationResult, setConfirmationResult] =
    useState<ConfirmationResult>();

  const signInWithPhone = async (
    phone: string,
    appVerifier: RecaptchaVerifier
  ) => {
    try {
      setLoading(true);
      const phoneRef = doc(firestore, 'phones', phone);
      const phoneSnap = await getDoc(phoneRef);
      let res;
      if (
        phoneSnap.exists() &&
        phoneSnap
          .data()
          .roles?.some((role) => ['fsa', 'distributor', 'tae', 'fsm'].includes(role))
      ) {
        res = await signInWithPhoneNumber(auth, phone, appVerifier);
      } else {
        await new Promise((resolve) => setTimeout(resolve, 400));
      }
      setConfirmationResult(res);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const verifyCode = async (code: string) => {
    try {
      setLoading(true);
      await confirmationResult.confirm(code);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  };

  const resArray: PhoneActionHook = [
    signInWithPhone,
    verifyCode,
    loading,
    error,
  ];
  return useMemo<PhoneActionHook>(() => resArray, resArray);
};

/* const checkIsRegistered = async (phone: string) => {
  const snap = await firestore.collection('phones').doc(phone).get();
  if (snap.exists) return true;
  return false;
}; */

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Layout from './components/layout/Layout';
import Protected from './components/routing/Protected';
import { AuthProvider } from './lib/context/auth.context';
import Home from './pages/home';
import Enter from './pages/enter';
import GoogleSignIn from './pages/enter/google';
import PhoneSignIn from './pages/enter/phone';
import Orders from './pages/orders';
import NewOrder from './pages/orders/new';
import Order from './pages/orders/[orderId]';
import EditOrder from './pages/orders/[orderId]/edit';
import Retailers from './pages/retailers';
import Retailer from './pages/retailers/[retailerId]';
import ErrorBoundary from './components/error/ErrorBoundary';
import Profile from './pages/profile';
import Denied from './pages/denied';
import NewRetailer from './pages/retailers/new';
import Content from './components/layout/Content';
import Card from './components/ui/Card';
import EditRetailer from './pages/retailers/[retailerId]/edit';
import { EditOutlet } from './pages/outlets/[outletId]/edit';
import { ToastContainer } from 'react-toastify';
import Notifications from './pages/notifications';
import { MapProvider } from './lib/context/map.context';
import 'react-toastify/dist/ReactToastify.css';
import Products from './pages/products';
import NewProduct from './pages/products/new';
import ProductOverview from './pages/products/[productId]';
import EditProduct from './pages/products/edit';

const App = () => {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <AuthProvider>
          <MapProvider>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Navigate to="home" />} />
                {/* Enter */}
                <Route path="enter" element={<Enter />} />
                <Route path="enter/google" element={<GoogleSignIn />} />
                <Route path="enter/phone" element={<PhoneSignIn />} />
                {/* Home */}
                <Route
                  path="home"
                  element={
                    <Protected>
                      <Home />
                    </Protected>
                  }
                />
                {/* Orders */}
                <Route
                  path="orders"
                  element={
                    <Protected roles={['sop', 'fsa', 'distributor', 'fsm']}>
                      <Orders />
                    </Protected>
                  }
                />
                <Route
                  path="orders/:id"
                  element={
                    <Protected roles={['sop', 'fsa', 'distributor', 'fsm']}>
                      <Order />
                    </Protected>
                  }
                />
                <Route
                  path="orders/:id/edit"
                  element={
                    <Protected roles={['sop', 'fsa', 'fsm']}>
                      <EditOrder />
                    </Protected>
                  }
                />
                <Route
                  path="orders/new"
                  element={
                    <Protected roles={['fsa', 'sop']}>
                      <NewOrder />
                    </Protected>
                  }
                />
                {/* Products */}
                <Route
                  path="products"
                  element={
                    <Protected roles={['sop', 'fsa', 'distributor', 'fsm']}>
                      <Products />
                    </Protected>
                  }
                />
                <Route
                  path="products/new"
                  element={
                    <Protected roles={['sop', 'fsa', 'distributor', 'fsm']}>
                      <NewProduct />
                    </Protected>
                  }
                />
                <Route
                  path="products/edit/:id"
                  element={
                    <Protected roles={['sop', 'fsa', 'distributor', 'fsm']}>
                      <EditProduct />
                    </Protected>
                  }
                />
                {/* Retailers */}
                <Route
                  path="retailers"
                  element={
                    <Protected roles={['sop', 'fsa', 'distributor', 'fsm']}>
                      <Retailers />
                    </Protected>
                  }
                />
                <Route
                  path="retailers/new"
                  element={
                    <Protected roles={['sop', 'fsa', 'fsm']}>
                      <NewRetailer />
                    </Protected>
                  }
                />
                <Route
                  path="retailers/:id"
                  element={
                    <Protected roles={['sop', 'fsa', 'distributor', 'fsm']}>
                      <Retailer />
                    </Protected>
                  }
                />
                <Route
                  path="retailers/:id/edit"
                  element={
                    <Protected roles={['sop', 'fsa', 'fsm']}>
                      <EditRetailer />
                    </Protected>
                  }
                />
                <Route
                  path="outlets/:id/edit"
                  element={
                    <Protected roles={['sop', 'fsa', 'fsm']}>
                      <EditOutlet />
                    </Protected>
                  }
                />
                {/* Profile */}
                <Route
                  path="profile"
                  element={
                    <Protected
                      roles={['sop', 'fsa', 'distributor', 'tae', 'fsm']}
                    >
                      <Profile />
                    </Protected>
                  }
                />
                {/* Notifications */}
                <Route
                  path="notifications"
                  element={
                    <Protected>
                      <Notifications />
                    </Protected>
                  }
                />
                {/* Fallback */}
                <Route path="/denied" element={<Denied />} />
                <Route
                  path="*"
                  element={
                    <Content>
                      <Card className="flex justify-center">
                        Page not found!
                      </Card>
                    </Content>
                  }
                />
              </Route>
              {/* Product Overview */}
              <Route path="products/:id" element={<ProductOverview />} />
            </Routes>
            <ToastContainer position="top-center" limit={3} />
          </MapProvider>
        </AuthProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default App;

import React from 'react';

type BurgerProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const Burger = (props: BurgerProps) => {
  const isExpanded = props.open ? true : false;

  const openHandler = () => {
    props.setOpen(!props.open);
  };

  return (
    <button
      aria-label="Toggle menu"
      aria-expanded={isExpanded}
      onClick={openHandler}
      type="button"
      className="fixed top-5 left-8 flex flex-col justify-around w-8 h-8 bg-transparent cursor-pointer p-0 z-40 focus:outline-none"
    >
      <div
        className={`w-8 h-1 bg-lh-head-black rounded-xl transition-all relative origin-top-left transform ${
          props.open ? 'rotate-45' : 'rotate-0'
        }`}
      ></div>
      <div
        className={`w-8 h-1 bg-lh-head-black rounded-xl transition-all relative origin-center transform ${
          props.open ? 'opacity-0 translate-x-5' : 'opacity-100 translate-x-0'
        }`}
      ></div>
      <div
        className={`w-8 h-1 bg-lh-head-black rounded-xl transition-all relative origin-bottom-left transform ${
          props.open ? '-rotate-45' : 'rotate-0'
        }`}
      ></div>
    </button>
  );
};

export default Burger;

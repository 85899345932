import { Link } from 'react-router-dom';
import Content from '../../components/layout/Content';
import Card from '../../components/ui/Card';

const Denied = () => {
  return (
    <Content>
      <Card className="flex flex-col justify-center">
        <h2 className="text-center font-bold">Access denied!</h2>
        <p className="text-center mb-6">
          Only registered users have access to the App. Please login with
          another account or contact{' '}
          <span className="font-thin">help@mcomtech.ch</span> for help.
        </p>
        <Link
          to={'/enter'}
          className="text-center w-1/3 self-center border rounded-md text-white cursor-pointer font-bold py-2 px-6 bg-blue-600 hover:bg-blue-400"
        >
          Login
        </Link>
      </Card>
    </Content>
  );
};

export default Denied;
